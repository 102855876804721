import React from "react" 


const GridIcons = () => (
  <div>
    
    <div className="row">
                <div className="col-md-4 text-center feature">
                  <i className="icon-graph" />
                  <h5>Privacy Ratings</h5>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-list-thumbnails" />
                  <h5>Policy Analysis &amp; History</h5>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-hierarchy" />
                  <h5>Third Party Risk</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center feature">
                  <i className="icon-bag" />
                  <h5>Data Collection</h5>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-clipboard" />
                  <h5>Standards &amp; Compliance</h5>
                </div>
                <div className="col-md-4 text-center feature">
                  <i className="icon-file-text" />
                  <h5>Transparency, Safety &amp; Trust</h5>
                </div>
              </div>
  </div>
)
export default GridIcons
